import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSquareDetails } from "../graphql/queries";
import { API } from "aws-amplify";
import {
  Box,
  Heading,
  Text,
  Anchor,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Spinner,
} from "grommet";
import { CheckboxSelected } from "grommet-icons";

export default function PaymentConfirmation() {
  const [receiptData, setReceiptData] = useState({});
  const [loading, setLoading] = useState(true);
  const params = useParams();

  useEffect(() => {
    console.log("params", params);
    checkPaymentStatus(params.id);
  }, [params]);

  async function checkPaymentStatus(id) {
    try {
      const data = await API.graphql({
        query: getSquareDetails,
        variables: {
          action: "checkPaymentStatus",
          payload: JSON.stringify({
            id: id,
          }),
        },
        authMode: "API_KEY",
      });
      setReceiptData(JSON.parse(data.data.getSquareDetails).body);
      setLoading(false);
      console.log("data", data);
    } catch (error) {
      console.error(error);
    }
  }
  if (loading) {
    return (
      <Box align="center" justify="center" pad="large" height="medium">
        <Spinner size="large" />
        <Text>Loading...</Text>
      </Box>
    );
  }

  return (
    <Box align="center" justify="center" pad="large">
      <Card pad="medium" gap="medium" width="large" border align="center">
        <CardHeader
          align="center"
          border="bottom"
          width="large"
          textAlign="center"
          justify="center"
        >
          <Box direction="column" gap="small" align="center" pad="small">
            <CheckboxSelected size="large" color="green" />
            <Heading level="3">Thank You!</Heading>
            <Text textAlign="center">
              {receiptData.product_name_and_option}
            </Text>
            <Text textAlign="center">{receiptData.tester_name}</Text>
            <Text textAlign="center">{receiptData.tester_email}</Text>
          </Box>
        </CardHeader>
        <CardBody>
          <Box direction="column" gap="medium" align="center">
            <Text textAlign="center">
              Your payment has been confirmed. You will receive an email
              confirmation shortly.
            </Text>
            <Text textAlign="center">Order Number</Text>
            <Text textAlign="center" size="xxlarge" weight="bolder">
              {params.id}
            </Text>
            <Text textAlign="center">
              Save, screenshot, or print this page for your records. You will
              need the order number to reference your payment when it is time to
              take your test!
            </Text>
            <Text textAlign="center">
              If you have any questions, please contact us at{" "}
              <Anchor href="mailto:info@monitoredu.com">
                info@monitoredu.com
              </Anchor>
            </Text>
          </Box>
        </CardBody>
        <CardFooter align="center">
          <Button
            primary
            color="black"
            label="View Receipt"
            onClick={() => {
              window.open(receiptData.receipt_url, "_blank");
            }}
          />
        </CardFooter>
      </Card>
    </Box>
  );
}
