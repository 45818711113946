import { API } from "aws-amplify";
import React, { useState, useEffect } from "react";
import { dbtExamPost, ListDemoCaveonExams } from "../graphql/queries";
import { Box, Text, Button, Accordion, AccordionPanel } from "grommet";

export default function DemoCaveonLinks() {
  const [exams, setExams] = useState([]);

  useEffect(() => {
    getExams();
  }, []);

  async function getExams() {
    try {
      const data = await API.graphql({
        query: ListDemoCaveonExams,
        variables: { limit: 1000 },
      });

      console.log(data);
      setExams(data.data.listDemoCaveonExams.items);
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <Box align="start" justify="start" width="100%">
      {exams.map((exam) => (
        <ExamRow key={exam.id} exam={exam} />
      ))}
    </Box>
  );
}

function ExamRow({ exam }) {
  const [proctor, setProctor] = useState("link");
  const [tester, setTester] = useState("link");
  const [isLinks, setIsLinks] = useState(false);

  async function handleGetLinks() {
    try {
      const data = await API.graphql({
        query: dbtExamPost,
        variables: { name: exam.name, email: exam.email },
      });
      console.log(data);
      const str = JSON.parse(data.data.dbtExamPost);
      setProctor("https://scorpion.caveon.com/proctor/" + str.proctor_token);
      setTester(
        "https://scorpion.caveon.com/take?launch_token=" + str.launch_token
      );
      setIsLinks(true);
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <Box
      direction="row"
      gap="medium"
      elevation="small"
      width="100%"
      height="xsmall"
      round="small"
      pad="small"
      align="center"
      justify="start"
    >
      <Text>{exam.name}</Text>
      <Text>{exam.email}</Text>
      <Button label="Get Links" onClick={handleGetLinks} />
      {isLinks && (
        <>
          <Button
            label="Open Proctor Link"
            onClick={() => window.open(proctor, "_blank")}
          />
          <Button
            label="Copy Tester Link"
            onClick={() => navigator.clipboard.writeText(tester)}
          />
        </>
      )}
    </Box>
  );
}
