/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateWebcamLink = /* GraphQL */ `
  subscription OnCreateWebcamLink {
    onCreateWebcamLink {
      id
      embedid
      roomid
      proctor
      tester
      status
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWebcamLink = /* GraphQL */ `
  subscription OnUpdateWebcamLink {
    onUpdateWebcamLink {
      id
      embedid
      roomid
      proctor
      tester
      status
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWebcamLink = /* GraphQL */ `
  subscription OnDeleteWebcamLink {
    onDeleteWebcamLink {
      id
      embedid
      roomid
      proctor
      tester
      status
      type
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWebcamRoom = /* GraphQL */ `
  subscription OnCreateWebcamRoom {
    onCreateWebcamRoom {
      id
      name
      embedCode
      ScCode
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWebcamRoom = /* GraphQL */ `
  subscription OnUpdateWebcamRoom {
    onUpdateWebcamRoom {
      id
      name
      embedCode
      ScCode
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWebcamRoom = /* GraphQL */ `
  subscription OnDeleteWebcamRoom {
    onDeleteWebcamRoom {
      id
      name
      embedCode
      ScCode
      type
      createdAt
      updatedAt
    }
  }
`;
export const onCreateInvigilExam = /* GraphQL */ `
  subscription OnCreateInvigilExam {
    onCreateInvigilExam {
      id
      examId
      title
      materials
      instructor
      email
      quantity
      instructions
      accom
      date
      format
      createdAt
      type
      custId
      updatedAt
    }
  }
`;
export const onUpdateInvigilExam = /* GraphQL */ `
  subscription OnUpdateInvigilExam {
    onUpdateInvigilExam {
      id
      examId
      title
      materials
      instructor
      email
      quantity
      instructions
      accom
      date
      format
      createdAt
      type
      custId
      updatedAt
    }
  }
`;
export const onDeleteInvigilExam = /* GraphQL */ `
  subscription OnDeleteInvigilExam {
    onDeleteInvigilExam {
      id
      examId
      title
      materials
      instructor
      email
      quantity
      instructions
      accom
      date
      format
      createdAt
      type
      custId
      updatedAt
    }
  }
`;
export const onCreateHpeExam = /* GraphQL */ `
  subscription OnCreateHpeExam {
    onCreateHpeExam {
      id
      candidateToken
      proctorToken
      examName
      examStart
      examDuration
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateHpeExam = /* GraphQL */ `
  subscription OnUpdateHpeExam {
    onUpdateHpeExam {
      id
      candidateToken
      proctorToken
      examName
      examStart
      examDuration
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteHpeExam = /* GraphQL */ `
  subscription OnDeleteHpeExam {
    onDeleteHpeExam {
      id
      candidateToken
      proctorToken
      examName
      examStart
      examDuration
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLsoTester = /* GraphQL */ `
  subscription OnCreateLsoTester {
    onCreateLsoTester {
      id
      email
      fName
      lName
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLsoTester = /* GraphQL */ `
  subscription OnUpdateLsoTester {
    onUpdateLsoTester {
      id
      email
      fName
      lName
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLsoTester = /* GraphQL */ `
  subscription OnDeleteLsoTester {
    onDeleteLsoTester {
      id
      email
      fName
      lName
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRoom = /* GraphQL */ `
  subscription OnCreateRoom {
    onCreateRoom {
      id
      name
      embedCode
      proctorCode
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRoom = /* GraphQL */ `
  subscription OnUpdateRoom {
    onUpdateRoom {
      id
      name
      embedCode
      proctorCode
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRoom = /* GraphQL */ `
  subscription OnDeleteRoom {
    onDeleteRoom {
      id
      name
      embedCode
      proctorCode
      type
      createdAt
      updatedAt
    }
  }
`;

export const onCreateSession = /* GraphQL */ `
  subscription OnCreateSession {
    onCreateSession {
      id
      first_name
      last_name
      client_name
      status
      proctor_history
    }
  }
`;

export const onUpdateSession = /* GraphQL */ `
  subscription OnUpdateSession($filter: ModelSubscriptionSessionFilterInput) {
    onUpdateSession(filter: $filter) {
      id
      status
      client_name
      first_name
      last_name
      proctor_history
    }
  }
`;

export const onUpdateTwilioSCRoom = /* GraphQL */ `
  subscription OnUpdateTwilioSCRoom(
    $filter: ModelSubscriptionTwilioSCRoomFilterInput
  ) {
    onUpdateTwilioSCRoom(filter: $filter) {
      createdAt
      id
      proctor
      status
      tester
      type
    }
  }
`;

export const onUpdateBackupQueue = /* GraphQL */ `
  subscription OnUpdateBackupQueue(
    $filter: ModelSubscriptionBackupQueueFilterInput
  ) {
    onUpdateBackupQueue(filter: $filter) {
      id
      meeting_link
    }
  }
`;
export const onProctorUpdateBackupQueue = /* GraphQL */ `
  subscription OnUpdateBackupQueue(
    $filter: ModelSubscriptionBackupQueueFilterInput
  ) {
    onUpdateBackupQueue(filter: $filter) {
      id
      client
      candidate_name
      exam
      meeting_link
      createdAt
      status
      proctor
    }
  }
`;

export const onCreateBackupQueue = /* GraphQL */ `
  subscription OnCreateBackupQueue {
    onCreateBackupQueue {
      id
      client
      candidate_name
      exam
    }
  }
`;

export const onDeleteBackupQueue = /* GraphQL */ `
  subscription OnDeleteBackupQueue {
    onDeleteBackupQueue {
      id
      client
      candidate_name
      exam
    }
  }
`;
