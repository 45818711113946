import { API } from "aws-amplify";
import {
  Box,
  Button,
  DateInput,
  Form,
  FormField,
  Grid,
  Heading,
  Text,
  TextInput,
} from "grommet";
import React, { useState } from "react";
import { CreateDemoCaveonExam } from "../graphql/mutations";

export default function DemoCaveonScheduling() {
  const [pageState, setPageState] = useState("form");
  const [submittedData, setSubmittedData] = useState({});
  const [isSelected, setIsSelected] = useState("");

  async function handleSubmit(event) {
    console.log(event);

    try {
      const data = await API.graphql({
        query: CreateDemoCaveonExam,
        variables: {
          input: {
            name: event.name,
            email: event.email,
            phone: event.phone,
            date: event.date + " " + isSelected,
          },
        },
        authMode: "API_KEY",
      });
      console.log(data);
      setSubmittedData(data.data.createDemoCaveonExam);
      setPageState("submitted");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Box align="center" justify="center" pad="large">
      {pageState === "form" && (
        <>
          <Box align="center">
            <Heading>Demo Scheduling Page</Heading>
            <Text>Fill out the form below!</Text>
          </Box>
          <Box width="large" gap="small" align="center">
            <Form onSubmit={({ value }) => handleSubmit(value)}>
              <FormField
                name="name"
                htmlFor="name_input"
                required
                border
                round="small"
              >
                <Text margin="small">Full Name</Text>
                <TextInput name="name" id="name" a11yTitle="Name" />
              </FormField>
              <FormField
                name="email"
                htmlFor="email_input"
                required
                border
                round="small"
              >
                <Text margin="small">Email</Text>
                <TextInput name="email" id="email" a11yTitle="Email" />
              </FormField>
              <FormField
                name="phone"
                htmlFor="phone_input"
                required
                border
                round="small"
              >
                <Text margin="small">Phone Number</Text>
                <TextInput name="phone" id="phone" a11yTitle="Phone" />
              </FormField>
              <Box border round="small" margin={{ bottom: "small" }}>
                <Text margin="small">Select an exam start date and time</Text>
                <Box direction="row" gap="small" justify="center">
                  <FormField name="date" htmlFor="date_input" required>
                    <DateInput
                      name="date"
                      id="date"
                      format="mm/dd/yyyy"
                      inline
                    />
                  </FormField>
                  <ButtonTimeList
                    isSelected={isSelected}
                    setIsSelected={setIsSelected}
                  />
                </Box>
              </Box>
              <Button primary color="black" label="Submit" type="submit" />
            </Form>
          </Box>
        </>
      )}

      {pageState === "submitted" && (
        <Box align="center" gap="medium">
          <Heading>Thank you for submitting!</Heading>
          <Text>Your exam has been scheduled!</Text>
          <Button
            label="Connect to a proctor"
            primary
            color="black"
            onClick={() =>
              window.open(
                "https://tawk.to/chat/675310034304e3196aed726a/1iee7d37q",
                "_blank"
              )
            }
          />
        </Box>
      )}
    </Box>
  );
}

function ButtonTimeList({ isSelected, setIsSelected }) {
  const times = [
    "8:00 AM",
    "9:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
    "4:00 PM",
    "5:00 PM",
  ];

  return (
    <Grid columns={["auto", "auto"]} gap="xxsmall">
      {times.map((time) => (
        <Button
          key={time}
          label={time}
          active={isSelected === time}
          onClick={() => setIsSelected(time)}
        />
      ))}
    </Grid>
  );
}
