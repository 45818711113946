import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Heading,
  CardBody,
  TextInput,
  Select,
  CardFooter,
  Button,
  //DateInput,
  Box,
  Notification,
  Text,
  Spinner,
} from "grommet";
import { v4 as uuidv4 } from "uuid";
import { API } from "aws-amplify";
import {
  addCmsAppointmentEmail,
  updateCmsRegistration,
} from "../graphql/mutations";

import {
  DateTimePicker,
  //DesktopDateTimePicker,
  LocalizationProvider,
  MobileDateTimePicker,
  StaticDateTimePicker,
} from "@mui/x-date-pickers";
import { renderDigitalClockTimeView } from "@mui/x-date-pickers/timeViewRenderers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

var minMax = require("dayjs/plugin/minMax");
var localizedFormat = require("dayjs/plugin/localizedFormat");
var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);
dayjs.extend(minMax);
dayjs.extend(localizedFormat);

export default function CmsScheduleCardTwo(props) {
  const [examDate, setExamDate] = useState("");
  const [windowStart, setWindowStart] = useState(null);
  const [windowEnd, setWindowEnd] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // check window and set window params
  useEffect(() => {
    if (props.window) {
      const windowArray = atob(props.window).split("&");

      const todayNow = dayjs();
      const startMax = dayjs.max([
        todayNow.add(1, "day"),
        dayjs(props.start),
        dayjs(windowArray[0]),
      ]);

      console.log("Start Max", startMax.format());

      setWindowStart(startMax);
      setExamDate(startMax);

      const endMin = dayjs.min([dayjs(props.end), dayjs(windowArray[1])]);

      console.log("End Min", endMin.format());

      setWindowEnd(endMin);
    } else {
      setWindowStart(dayjs.max([dayjs().add(1, "day"), dayjs(props.start)]));
      setExamDate(dayjs.max([dayjs().add(1, "day"), dayjs(props.start)]));
      setWindowEnd(dayjs(props.end));
    }

    setInProgress(true);
  }, []);

  const handleDateTimeChange = (date) => {
    setExamDate(date);
  };

  const handleSubmit = async () => {
    // validate exam date in window
    console.log("Exam Date: ", examDate);
    console.log("ISO: ", examDate.toISOString());
    console.log(examDate.isBetween(windowStart, windowEnd));
    if (!examDate.isBetween(windowStart, windowEnd)) {
      alert("Please select a date and time within the exam window.");
      return;
    } else {
      try {
        let stat;
        if (props.action === "reschedule") {
          stat = "rescheduled";
        } else if (props.action === "schedule") {
          stat = "scheduled";
        } else {
          stat = null;
        }
        setInProgress(false);
        const data = await API.graphql({
          query: updateCmsRegistration,
          variables: {
            input: {
              registration_id: props.registration,
              appointment: {
                appointment_id: uuidv4(),
                exam_date: examDate.toISOString(),
                local_date: examDate.format("LLLL"),
              },
              status: stat,
            },
          },
          authMode: "API_KEY",
        });

        console.log(data);
        const zap = await API.graphql({
          query: addCmsAppointmentEmail,
          variables: {
            body: {
              name: props.first + " " + props.last,
              email: props.email,
              exam: props.examName,
              date: examDate.toISOString(),
            },
          },
          authMode: "API_KEY",
        });

        if (data.data.updateCmsRegistration.appointment.exam_date !== null) {
          setSuccess(true);
          setLoading(false);
        } else {
          setInProgress(true);
          setLoading(false);
          alert("There was an error scheduling your exam. Please try again.");
        }
      } catch (error) {
        console.log("Error: ", error);
        setInProgress(true);
        setLoading(false);
        alert("There was an error scheduling your exam. Please try again.");
      }
    }
  };

  return (
    <Box align="center" justify="center" pad="small" gap="medium">
      <Card
        background="background-front"
        elevation="medium"
        width="large"
        pad="small"
        align="center"
        justify="center"
        overflow="auto"
      >
        {success && <Text weight="bold"> Exam Scheduled Successfully! </Text>}
        {inProgress && (
          <Box>
            <CardHeader
              align="center"
              justify="center"
              direction="row"
              flex={false}
              gap="medium"
            >
              <Heading
                textAlign="center"
                size="small"
                level="3"
                truncate={false}
              >
                Please Select An Exam Date and Start Time
              </Heading>
            </CardHeader>
            <CardBody pad="small" gap="medium">
              <TextInput
                value={props.first + " " + props.last}
                placeholder="First Last"
                disabled
              />
              <TextInput placeholder="Email" value={props.email} disabled />
              <TextInput placeholder="Exam" value={props.examName} disabled />
              <Text weight="bold">Select A Date and Time</Text>
              <NewDatePicker
                startDate={windowStart}
                endDate={windowEnd}
                value={examDate}
                onChange={(date) => handleDateTimeChange(date)}
              />
              <Button onClick={handleSubmit} primary label="Submit" />
            </CardBody>
          </Box>
        )}
      </Card>
    </Box>
  );
}

function NewDatePicker({ startDate, endDate, value, onChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Text weight="bold">Exam Window: </Text>
      <Box direction="row" gap="small">
        <Text>
          {startDate.format("L LT")} - {endDate.format("L LT")}{" "}
        </Text>
      </Box>
      <Box direction="row" gap="small">
        <Text weight="bold">Selected Date: </Text>
        <Text>{dayjs(value).format("LLLL")}</Text>
      </Box>

      <StaticDateTimePicker
        disablePast={true}
        value={dayjs(value)}
        onChange={onChange}
        minDateTime={dayjs(startDate)}
        maxDateTime={dayjs(endDate)}
        slotProps={{
          actionBar: {
            hidden: true,
            actions: [],
          },
          toolbar: {
            hidden: true,
          },
        }}
        viewRenderers={{
          hours: renderDigitalClockTimeView,
          minutes: renderDigitalClockTimeView,
        }}
        shouldDisableDate={(date) => {
          if (
            date.format("LLLL").includes("December 24") ||
            date.format("LLLL").includes("December 25")
          ) {
            return true;
          }
        }}
      />
    </LocalizationProvider>
  );
}
