/*
Non autherized user header, only includes logo
*/

import React from "react";
import { Box } from "grommet";
import logo from "../monitoredu-logo.svg";
function NavUnAuth() {
  return (
    <Box
      overflow="auto"
      align="center"
      flex="grow"
      fill
      responsive
      gap="none"
      background={{ color: "active", opacity: "medium" }}
    >
      <img
        src={logo}
        alt="MonitorEDU Logo"
        style={{ height: "1fr", width: "250px" }}
      ></img>
    </Box>
  );
}

export default NavUnAuth;
