import {
  Box,
  Heading,
  TextInput,
  Select,
  Button,
  Text,
  Form,
  FormField,
  Layer,
  Notification,
} from "grommet";

import React, { useState } from "react";
import { fdmsExamGet } from "../graphql/queries";
import { API } from "aws-amplify";
//const axios = require("axios").default;

function FDMS() {
  const [proctor, setProctor] = useState("");
  const [exam, setExam] = useState("");
  const [isExam, setIsExam] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [isConfirm, setIsConfirm] = useState(false);
  const [value, setValue] = useState({
    first: "",
    last: "",
    email: "",
    ins: "",
    examName: "",
  });
  const [showProc, setShowProc] = useState(false);
  const [showExam, setShowExam] = useState(false);
  const [notify, setNotify] = useState(false);
  const [link, setLink] = useState("");
  const examOptions = [
    "Florida Certified Contract Manager (FCCM) Certification Exam",
    "Florida Certified Contract Negotiator (FCCN) Certification Exam",
  ];
  async function handleSubmit() {
    console.log(value);

    const data = await API.graphql({
      query: fdmsExamGet,
      variables: {
        first: value.first,
        last: value.last,
        email: value.email,
        ins: value.ins,
        exam: value.examName,
      },
    });
    const par = JSON.parse(data.data.fdmsExamGet);
    console.log(par);

    setProctor(par.proctor_token);
    setExam(par.launch_token);
    setIsConfirm(false);
    setCurrentValue(value);
    setValue({ first: "", last: "", email: "", ins: "", examName: "" });
    setIsExam(true);
  }

  const handleCopy = (val, type) => {
    setLink(type);
    navigator.clipboard.writeText(val);
    setNotify(true);
  };

  return (
    <Box align="center" justify="center">
      {isConfirm && (
        <Layer
          position="center"
          modal
          onClickOutside={() => setIsConfirm(false)}
        >
          <Box pad="medium" gap="small" width="medium">
            <Heading level={4} margin="none">
              CONFIRM THIS INFO IS CORRECT
            </Heading>
            <Text>First Name: {value.first}</Text>
            <Text>Last Name: {value.last}</Text>
            <Text>Email: {value.email}</Text>
            <Text>Instructor: {value.ins}</Text>
            <Text>Exam: {value.examName}</Text>
            <Button label="Confirm" primary onClick={() => handleSubmit()} />
            <Button label="Cancel" onClick={() => setIsConfirm(false)} />
          </Box>
        </Layer>
      )}

      {notify && (
        <Notification
          toast
          status="normal"
          title={link + " link copied to clipboard"}
          onClose={() => setNotify(false)}
          time={1500}
        />
      )}
      <Heading>FDMS Links</Heading>
      <Box
        direction="row"
        gap="medium"
        justify="center"
        align="start"
        pad="xsmall"
      >
        <Box align="center" justify="center" gap="small" width="large">
          <Form
            value={value}
            onChange={(nextValue) => {
              console.log(nextValue);
              setValue(nextValue);
            }}
            onSubmit={() => setIsConfirm(true)}
            style={{ width: "100%" }}
            infos={{ examName: "CHECK TAWK PROPERTY FOR EXAM NAME" }}
          >
            <FormField label="Tester First Name" name="first" required={true}>
              <TextInput placeholder="Tester First Name" name="first" />
            </FormField>
            <FormField label="Tester Last Name" name="last" required={true}>
              <TextInput placeholder="Tester Last Name" name="last" />
            </FormField>
            <FormField
              label="Tester State Issued Email"
              name="email"
              required={true}
            >
              <TextInput
                placeholder="Tester State Issued Email"
                name="email"
                type="email"
              />
            </FormField>
            <FormField label="Instructor" name="ins" required={true}>
              <Select
                placeholder="Instructor"
                options={[
                  "Alison.owens@dms.fl.gov",
                  "Ashley.sellars@dms.fl.gov",
                  "Lisa.Eaton@dms.fl.gov",
                  "Ricky.Lay@dms.fl.gov",
                ]}
                name="ins"
              />
            </FormField>
            <FormField label="Exam" name="examName" required={true}>
              <Select
                placeholder="Exam Name"
                options={examOptions}
                name="examName"
              />
            </FormField>
            <Button label="Submit" type="submit" />
          </Form>
        </Box>
        {isExam && (
          <Box
            align="start"
            justify="start"
            gap="small"
            margin={{ left: "medium" }}
          >
            <hr />
            <br />
            <Text>Links For:</Text>
            <Text>
              {currentValue.first} {currentValue.last}
            </Text>
            <Text> {currentValue.email}</Text>
            <Text> {currentValue.ins}</Text>
            <Text> {currentValue.examName}</Text>
            <Box direction="row" gap="small">
              <Button
                primary
                label="Copy Proctor Link"
                onClick={() =>
                  handleCopy(
                    `https://scorpion.caveon.com/proctor/${proctor}`,
                    "proctor"
                  )
                }
              />
              <Button
                label={showProc ? "Hide Proctor Link" : "Show Proctor Link"}
                onClick={() => setShowProc(!showProc)}
              />
            </Box>
            {showProc && (
              <Text>
                Proctor Link: https://scorpion.caveon.com/proctor/{proctor}
              </Text>
            )}
            <Box direction="row" gap="small">
              <Button
                primary
                label="Copy Examinee Link"
                onClick={() =>
                  handleCopy(
                    `Exam Link for ${currentValue.first} ${currentValue.last} - ${currentValue.examName} https://scorpion.caveon.com/take?launch_token=${exam}`,
                    "tester"
                  )
                }
              />
              <Button
                label={showExam ? "Hide Examinee Link" : "Show Examinee Link"}
                onClick={() => setShowExam(!showExam)}
              />
            </Box>
            {showExam && (
              <Text>
                Examinee Link: https://scorpion.caveon.com/take?launch_token=
                {exam}
              </Text>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default FDMS;
