import { API, Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import {
  BackupQueueByType,
  getGoogleRoomDetails,
  getUser,
} from "../graphql/queries";
import {
  UpdateBackupQueue,
  DeleteBackupQueue,
  createUser,
  updateUser,
} from "../graphql/mutations";

import {
  onCreateBackupQueue,
  onProctorUpdateBackupQueue,
  onDeleteBackupQueue,
} from "../graphql/subscriptions";
import {
  Box,
  Button,
  Sidebar,
  Text,
  Layer,
  Spinner,
  Notification,
} from "grommet";
import { useGoogleLogin } from "@react-oauth/google";

export default function ProctorBackupQueue() {
  const [queue, setQueue] = useState([]);
  const [mine, setMine] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [showGoogleLogin, setShowGoogleLogin] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const useThisEmail = sessionStorage.getItem("user");
    const email = JSON.parse(useThisEmail).attributes.email;
    Auth.currentUserInfo().then((user) => {
      setCurrentUser(user);
    });

    // list all current proctor backup queue
    listQueue();
    // subscribe to oncreate, add new item to queue
    const createSub = API.graphql({
      query: onCreateBackupQueue,
    }).subscribe({
      next: ({ value }) => {
        console.log("onCreateBackupQueue", value);
        setQueue((prev) => [...prev, value.data.onCreateBackupQueue]);
      },
      error: (error) => console.warn(error),
    });
    // subscribe to onupdate where status is changed to "claimed", remove item from queue
    const updateSub = API.graphql({
      query: onProctorUpdateBackupQueue,
      variables: {
        filter: {
          status: { eq: "claimed" },
        },
      },
    }).subscribe({
      next: ({ value }) => {
        console.log("onUpdateRemove", value);

        setQueue((prev) =>
          prev.filter((q) => q.id !== value.data.onUpdateBackupQueue.id)
        );
        if (value.data.onUpdateBackupQueue.proctor === email) {
          // check if it already exists in mine and replace if it does
          setMine((prev) => {
            if (prev.some((q) => q.id === value.data.onUpdateBackupQueue.id)) {
              return prev.map((q) =>
                q.id === value.data.onUpdateBackupQueue.id
                  ? value.data.onUpdateBackupQueue
                  : q
              );
            } else {
              return [...prev, value.data.onUpdateBackupQueue];
            }
          });
        }
      },
      error: (error) => console.warn(error),
    });
    // subscribe to onupdate where status equals queue, add item back to queue
    const updateSub2 = API.graphql({
      query: onProctorUpdateBackupQueue,
      variables: {
        filter: {
          status: { eq: "queue" },
        },
      },
    }).subscribe({
      next: ({ value }) => {
        console.log("onUpdateAdd", value);
        setQueue((prev) => [...prev, value.data.onUpdateBackupQueue]);
        // remove from mine
        setMine((prev) =>
          prev.filter((q) => q.id !== value.data.onUpdateBackupQueue.id)
        );
      },
      error: (error) => console.warn(error),
    });

    const deleteSub = API.graphql({
      query: onDeleteBackupQueue,
    }).subscribe({
      next: ({ value }) => {
        console.log("onDeleteBackupQueue", value);
        setQueue((prev) =>
          prev.filter((q) => q.id !== value.data.onDeleteBackupQueue.id)
        );
        setMine((prev) =>
          prev.filter((q) => q.id !== value.data.onDeleteBackupQueue.id)
        );
      },
      error: (error) => console.warn(error),
    });

    return () => {
      createSub.unsubscribe();
      updateSub.unsubscribe();
      updateSub2.unsubscribe();
      deleteSub.unsubscribe();
    };
  }, []);

  async function getUserEmail() {
    const user = await Auth.currentAuthenticatedUser();
    return user.attributes.email;
  }

  async function listQueue() {
    const email = await getUserEmail();
    setUserEmail(email);

    try {
      const data = await API.graphql({
        query: BackupQueueByType,
        variables: { limit: 1000, type: "BackupQueue", SortDirection: "DESC" },
      });
      console.log("data", data);
      setQueue(
        data.data.backupQueueByType.items.filter((q) => q.status === "queue")
      );
      let mine = data.data.backupQueueByType.items.filter(
        (q) => q.proctor === email
      );
      console.log("mine", mine);
      if (mine.length > 0) {
        setMine(mine);
      }
    } catch (error) {
      console.log("error on fetching queue", error);
    }
  }

  async function claimQueue(id) {
    try {
      const data = await API.graphql({
        query: UpdateBackupQueue,
        variables: {
          input: {
            id: id,
            status: "claimed",
            proctor: userEmail,
          },
        },
      });
      console.log("data", data);
    } catch (error) {
      console.log("error on claiming queue", error);
    }
  }

  async function createRoomPrompt(session) {
    // check if user exists and has google access token
    setShowLoader(true);
    const check = await API.graphql({
      query: getUser,
      variables: {
        id: currentUser.id,
      },
    });
    console.log(check);
    if (check.data.getUser === null) {
      await API.graphql({
        query: createUser,
        variables: {
          input: {
            id: currentUser.id,
            email: currentUser.attributes.email,
          },
        },
      });
      setShowGoogleLogin(true);
    } else if (check.data.getUser.google_access_token === null) {
      setShowGoogleLogin(true);
    } else {
      // generate room link
      // send up access, refresh, and expiry
      // double check colibri for the room since they have a different token
      createTheRoom(session);
    }
  }

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log(codeResponse);
      setGoogleTokens(codeResponse.code);
    },
    flow: "auth-code",
    redirect_uri: "postmessage",
    scope:
      "https://www.googleapis.com/auth/meetings.space.readonly https://www.googleapis.com/auth/meetings.space.created https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/drive.meet.readonly",
  });

  async function setGoogleTokens(code) {
    await API.graphql({
      query: getGoogleRoomDetails,
      variables: {
        action: "getInitialTokens",
        payload: JSON.stringify({
          code: code,
        }),
      },
    }).then((data) => {
      console.log(data);
      let parsed = JSON.parse(data.data.getGoogleRoomDetails);
      console.log(parsed);
      if (parsed.statusCode === 200) {
        updateUserCreds(
          currentUser.id,
          parsed.body.access_token,
          parsed.body.refresh_token,
          parsed.body.expiry_date
        );
        setShowGoogleLogin(false);
      }
    });
  }

  async function updateUserCreds(id, access_token, refresh_token, expiry_date) {
    await API.graphql({
      query: updateUser,
      variables: {
        input: {
          id: id,
          google_access_token: access_token,
          google_refresh_token: refresh_token,
          google_expiry_date: expiry_date,
        },
      },
    }).then((data) => {
      console.log(data);
      createTheRoom();
    });
  }

  async function createTheRoom(session) {
    const userTokens = await API.graphql({
      query: getUser,
      variables: {
        id: currentUser.id,
      },
    });
    console.log(userTokens);

    const meetRoom = await API.graphql({
      query: getGoogleRoomDetails,
      variables: {
        action: "createQuickRoom",
        payload: JSON.stringify({
          googleAccessToken: userTokens.data.getUser.googleAccessToken,
          googleRefreshToken: userTokens.data.getUser.googleRefreshToken,
          googleTokenExpiry: userTokens.data.getUser.TokenExpiry,
        }),
      },
    });
    console.log(JSON.parse(meetRoom.data.getGoogleRoomDetails));
    const gRoom = JSON.parse(meetRoom.data.getGoogleRoomDetails).body[0]
      .meetingUri;

    // update the room link
    const data = await API.graphql({
      query: UpdateBackupQueue,
      variables: {
        input: {
          id: session,
          meeting_link: gRoom,
        },
      },
    });

    console.log(data);

    setShowLoader(false);
  }

  return (
    <Box direction="row" height={{ max: "90vh" }}>
      {showGoogleLogin && (
        <Layer>
          <Box
            align="center"
            justify="center"
            fill="horizontal"
            pad="medium"
            gap="small"
          >
            <Text>
              We need permission to access Google Meets, click below to
              authenticate.
            </Text>
            <Button
              label="Authenticate with Google"
              primary
              onClick={() => login()}
            />
          </Box>
        </Layer>
      )}
      <Sidebar
        elevation="small"
        round="small"
        width={{ min: "small" }}
        overflow="scroll"
      >
        <Box align="start" justify="start">
          <Notification background="status-ok" margin="small" toast />
          <h3>Queue</h3>
          {queue.map((q) => (
            <Button
              key={q.id}
              onClick={() => claimQueue(q.id)}
              hoverIndicator
              fill="horizontal"
              tip={{
                content: "Click to claim",
                dropProps: {
                  align: { left: "right" },
                },
              }}
            >
              <Box align="start" justify="start" pad="small">
                <Text weight="bold" size="small">
                  {q.client}
                </Text>
                <Text size="small">{q.candidate_name}</Text>
              </Box>
            </Button>
          ))}
        </Box>
      </Sidebar>
      <Box margin={{ left: "small", top: "small" }}>
        <h3>Mine</h3>
        <Box align="start" justify="start" direction="row" wrap>
          {mine.map((q) => (
            <MyCard key={q.id} entry={q} createRoomPrompt={createRoomPrompt} />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

function MyCard({ entry, createRoomPrompt }) {
  const [show, setShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    } else if (!showLoader) {
      setShowLoader(false);
    }
  }, [entry]);

  async function sendToQueue() {
    await API.graphql({
      query: UpdateBackupQueue,
      variables: {
        input: {
          id: entry.id,
          status: "queue",
          proctor: null,
        },
      },
    });
  }

  async function deleteEntry() {
    await API.graphql({
      query: DeleteBackupQueue,
      variables: {
        input: {
          id: entry.id,
        },
      },
    });

    setShow(false);
  }

  return (
    <Box
      direction="row"
      elevation="small"
      round="small"
      margin="small"
      pad="small"
      width="small"
      align="center"
      justify="center"
    >
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
        >
          <Box pad="small" align="center" justify="center" gap="medium">
            <Text>Do you wish to delete this entry?</Text>
            <Text>This will completely remove the session</Text>
            <Text>Make sure the tester is in the google room first</Text>
            <Text weight="bold">
              {entry.client} - {entry.exam}
            </Text>
            <Text>{entry.candidate_name}</Text>
            <Button label="Yes" primary onClick={deleteEntry} />
            <Button
              label="No"
              onClick={() => setShow(false)}
              primary
              color="black"
            />
          </Box>
        </Layer>
      )}
      <Box align="center" justify="center">
        <Box align="center" justify="center">
          {" "}
          <Text weight="bold">
            {entry.client} - {entry.exam}
          </Text>
          <Text>{entry.candidate_name}</Text>
        </Box>

        <Box align="center" justify="center">
          {entry.meeting_link ? (
            <Button
              href={entry.meeting_link}
              target="_blank"
              label="Join Meeting"
              primary
            />
          ) : (
            <Button
              label="Create google room"
              primary
              onClick={() => {
                setShowLoader(true);
                createRoomPrompt(entry.id);
              }}
            />
          )}

          <Button label="Send to Queue" onClick={sendToQueue} />
          <Button
            label="Delete"
            tip="This will completely delete the session, make sure the tester is in the google room first"
            onClick={() => setShow(true)}
          />
        </Box>
      </Box>
    </Box>
  );
}
